import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AvailabilityFormComponent } from '../availability-form/availability-form.component';


@Component({
  selector: 'ngx-availability-info',
  templateUrl: './availability-info.component.html',
  styleUrls: ['./availability-info.component.scss']
})
export class AvailabilityInfoComponent implements OnInit {

  constructor(private ref:NbDialogRef<AvailabilityInfoComponent>,private dialog:NbDialogService) { }
  @Input() data:any;
  @Input() groupOnline: boolean = false;
  onlineDomains: string[] = ['ONLINE_DELIVERY', 'ONLINE_PICKUP', 'ONLINE_APP_DELIVERY', 'ONLINE_APP_PICKUP', 'TABLE_SIDE'];

  availability=[];
  //availability = [ { "domain": [ "POS", "ONLINE" ], "days": [ "0", "3", "4" ], "hours": [ { "Start": "20:07", "End": "22:00" } ], "startDate": "2024-04-01", "endDate": "2024-04-30" }, { "domain": [ "POS" ], "days": [ "1" ], "hours": [ { "Start": "01:00", "End": "18:00" } ] } ];
  ngOnInit(): void {

    if(this.data){
      console.log(this.data);
      this.availability = this.data;
    }

  }


  close(status:any){
    this.ref.close();
  }

  remove(i){
    this.availability.splice(i,1);
  }

  addNew(){
   const dialog= this.dialog.open(AvailabilityFormComponent, {
      context: {
        title: 'Add Availability',
        type: 'add',
        data: null,
        groupOnline: this.groupOnline
      },
    });
    dialog.onClose.subscribe((res) => {
      if(res.status){
        this.availability.push(res.data);
        console.log('check Point', this.availability);
      }
    })
  }
  edit(data,i){
   const dialog= this.dialog.open(AvailabilityFormComponent, {
      context: {
        title: 'edit Availability',
        type: 'edit',
        data: data,
        index:i,
        groupOnline: this.groupOnline
      },
    });
    dialog.onClose.subscribe((res) => {
      if(res.status){
        this.availability[res.index] = res.data;
        console.log('check Point', this.availability);
      }
    })
  }

  getDay(day){
    return ['Sun ','Mon ','Tue ','Wed ','Thu ','Fri ','Sat '][day]
  }


  save(){
    this.ref.close({status:true,data:this.availability});
  }


}
