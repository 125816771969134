<nb-card class="c-body">
    <nb-card-header>
        Availability Form
    </nb-card-header>
    <nb-card-body>

        <div class="form-item-box">
            <fieldset>
                <legend>Domain</legend>
                <div class="d-check-item select-all" nbTooltip="Select All">Select All
                    <nb-checkbox [(checked)]="selectAllCheckedDomain" (checkedChange)="toggleSelectAll($event)"></nb-checkbox>
                </div>
                <div class="domainlist">
                    
                    <div class="d-check-item">POS 
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('POS') > -1" (checkedChange)="toggleDomain($event,'POS')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> ONLINE DELIVERY
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('ONLINE_DELIVERY') > -1" (checkedChange)="toggleDomain($event,'ONLINE_DELIVERY')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> ONLINE PICKUP
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('ONLINE_PICKUP') > -1" (checkedChange)="toggleDomain($event,'ONLINE_PICKUP')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> ONLINE APP DELIVERY
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('ONLINE_APP_DELIVERY') > -1" (checkedChange)="toggleDomain($event,'ONLINE_APP_DELIVERY')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> ONLINE APP PICKUP
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('ONLINE_APP_PICKUP') > -1" (checkedChange)="toggleDomain($event,'ONLINE_APP_PICKUP')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> TABLE SIDE
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('KIOSK') > -1" (checkedChange)="toggleDomain($event,'TABLE_SIDE')"></nb-checkbox>
                    </div>
                    <div class="d-check-item"> KIOSK
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('DM')&&form?.DM.indexOf('KIOSK') > -1" (checkedChange)="toggleDomain($event,'KIOSK')"></nb-checkbox>
                    </div>
                </div>
            </fieldset>


        </div>
        <div>
            <fieldset>
                <legend>Days</legend>
                <div class="d-check-item select-all" nbTooltip="Select All">Select All
                    <nb-checkbox [(checked)]="selectAllCheckedDays" (checkedChange)="toggleSelectAllDays($event)"></nb-checkbox>
                </div>
                <div class="dayslist">
                    <div class="d-check-item">SUN
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('0') > -1" (checkedChange)="toggleDay($event,'0')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">MON
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('1') > -1" (checkedChange)="toggleDay($event,'1')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">TUE
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('2') > -1" (checkedChange)="toggleDay($event,'2')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">WED
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('3') > -1" (checkedChange)="toggleDay($event,'3')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">THU
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('4') > -1" (checkedChange)="toggleDay($event,'4')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">FRI
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('5') > -1" (checkedChange)="toggleDay($event,'5')"></nb-checkbox>
                    </div>
                    <div class="d-check-item">SAT
                        <nb-checkbox [checked]="form&&form.hasOwnProperty('D')&&form?.D.indexOf('6') > -1" (checkedChange)="toggleDay($event,'6')"></nb-checkbox>
                    </div>
                </div>
            </fieldset>
        </div>


        <div>
            <fieldset>
                <legend>Hours</legend>

                <div class="existing-hours" *ngIf="form&&form?.H">
                    <div class="existing-hour" *ngFor="let item of form?.H;index  as i">
                        <div>{{item.S}} - {{item.E}}</div>
                        <button class="remove" (click)="removeTime(i)">X</button>
                    </div>
                </div>

                <div class="hours-item">
                    <div class="time-box">
                        <label class="label" for="from">Start</label>
                        <input type="time" class="time-input" [(ngModel)]="fromtime">
                    </div>
                    <div class="time-box">
                        <label class="label" for="end">End</label>
                        <input type="time" class="time-input" [(ngModel)]="totime">
                    </div>
                    <div>
                        <button class="btn btn-secondary" (click)="addTime()">ADD</button>
                    </div>
                </div>

            </fieldset>


        </div>
        <div>
            <div>
                <fieldset>
                    <legend>Date Range</legend>
                    <input nbInput placeholder="Pick Date Range" [(ngModel)]="daterange" [nbDatepicker]="formpicker" (ngModelChange)="changeDate($event)">
                    <nb-rangepicker #formpicker></nb-rangepicker>

                    <button nbButton status="danger" class="mx-2" (click)="clearDate()">Clear</button>

                </fieldset>
            </div>

        </div>




    </nb-card-body>
    <nb-card-footer>
        <div class="d-foot-action">
            <button nbButton type="button" status="danger" (click)="close(false)">CANCEL</button>
            <button nbButton type="button" class="mx-2" status="success" (click)="save()">{{type=='add'?'Create':'Update'}}</button>
        </div>

    </nb-card-footer>
</nb-card>