import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamelCasePipe } from '../pipes/camal-case.pipe';



@NgModule({
  declarations: [CamelCasePipe],
  imports: [
    CommonModule
  ],
  exports: [
    CamelCasePipe // Export the pipe so it can be used in other modules
  ],
})
export class SharedModule { }
