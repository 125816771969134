import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return value; // Return if the value is null or undefined
    }

    // Replace underscores with spaces, then capitalize the first letter of each word
    return value
      .toLowerCase() // Convert the entire string to lowercase
      .split('_') // Split the string into parts by '_'
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with a space
  }

}